import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import './jobs-header.sass'
import { countryCodes } from '../../../config/countries'

export function JobsHeader({ className, country }) {
  return (
    <div className={`jobs-header ${className}`}>
      <Link to="/">
        {country === countryCodes.SA ? (
          <StaticImage
            src="../../../assets/common__logo-ksa.png"
            alt="Ogram: Find Part-Time Jobs"
            placeholder="blurred"
            className="jobs-header__logo jobs-header__logo-ksa"
          />
        ) : (
          <StaticImage
            src="../../../assets/common__logo.png"
            alt="Ogram: Find Part-Time Jobs"
            placeholder="blurred"
            className="jobs-header__logo"
          />
        )}
      </Link>
    </div>
  )
}